import { useAlert } from "react-alert";
import { useDispatch,useSelector } from "react-redux";
import bg7 from '../bg7.webm';

const {useState, useEffect} = require('react');
const {login} = require('../features/user/userSlice');

const Login = () => {

    const {authtoken,role} = useSelector(state => state.user);

    // used for showing alerts
    const alert = useAlert();
    const dispatch = useDispatch();

    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");

    const [emailerror,setEmailerror] = useState("");
    const [passworderror,setPassworderror] = useState("");

    useEffect(()=>{
        checklogin();        
    },[]);

    function isEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const checklogin = async () => {
        let res;
        let logindetails = localStorage.getItem('kidwailogin');
        logindetails = await JSON.parse(logindetails);
        if(!(logindetails===null))   
        {
            let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_JWTVAL;

            try
            {
                res = await fetch(url,{
                    headers:{
                        "Authorization":"Token " + logindetails.authtoken,
                    }
                })
                
                res = await res.json();
            }
            catch(error)
            {
                alert.error("Session Expired, Login Again");
                console.log(error);
                return;
            }

            if(res.status === "Failure")
            {
                alert.error("Session Expired, Login Again");
            }
            else if(res.status === "Success")
            {
                logindetails.authtoken = res.token;
                console.log(logindetails);
                dispatch(login(logindetails));
                localStorage.setItem('kidwailogin', JSON.stringify(logindetails));
            }
            return;
        }
    }

    const handlelogin = async (e) => {
        e.preventDefault();

        let res;

        if(email==="" || password==="")
        {
            alert.error("Please enter email and password.");
            return;
        }

        if(!isEmail(email))
        {
            setEmailerror("Enter a proper email address");
        }

        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_LOGIN;

        console.log(url);

        try
        {
            res = await fetch(url,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({"email":email, "password":password})
                })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when logging in");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            alert.success("Successfully logged in");
            console.log(res);
            const logindetails = {
                firstname: res.user.firstname,
                lastname: res.user.lastname,
                email: res.user.email,
                role: res.user.role,
                authtoken: res.token,
                isloggedin: true
            }
            localStorage.setItem('kidwailogin', JSON.stringify(logindetails));
            dispatch(login(logindetails));
        }

    }

    return (
        <>
            <video className='bgvideo' autoPlay loop muted>
                <source src={bg7} type='video/mp4' />
            </video>
            <div className="absolute flex flex-col w-full h-full loginbg">
                <div className="font-extrabold text-6xl text-white leading-10 pt-8 mt-6 p-6 w-full flex justify-center">
                    Kidwai
                </div>
                <div className="flex w-full h-full mt-4 justify-center mb-7">
                    <form method='Post'  onSubmit={handlelogin}>
                        <div className="bg-white flex flex-col justify-center border rounded-2xl w-[450px] h-[450px] text-xl">
                            <span className="text-center text-3xl font-bold mt-3">Welcome back!</span>
                            <span className="text-center text-md mt-3 gilreg">Enter your information to login.</span>
                            <br></br>
                            <div className="p-3">
                                <label name="email">Email</label>
                                <br></br>
                                <input type="text" htmlFor="email" value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    className="border border-2 rounded-md p-1 my-1 w-full"
                                >
                                </input>
                                <span className="text-[#9a031e]">{emailerror}</span>
                            </div>
                            <div className="p-3">
                                <label name="password">Password</label>
                                <br></br>
                                <input type="password" htmlFor="password" value={password}
                                    onChange={(e)=>setPassword(e.target.value)}
                                    className="border border-2 rounded-md p-1 my-1 w-full"
                                >
                                </input>
                            </div>

                            <button type="submit" class="m-4 relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-black transition duration-300 ease-out border-2 border-black rounded-full shadow-md group">
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-black group-hover:translate-x-0 ease">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span class="absolute flex items-center justify-center w-full h-full text-black text-2xl transition-all duration-300 transform group-hover:translate-x-full ease">Login</span>
                                <span class="relative invisible text-2xl">Login</span>
                            </button>

                            {/* <button className="button border rounded-2xl m-3 my-4 p-1 bg-blue-900 text-white text-xl" type="submit">
                                Login
                            </button> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login;