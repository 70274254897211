import { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import AppointmentFlow from "./AppointmentFlow";
import AppointmentsList from '../components/AppointmentsList';
import Reports from './Reports';

const FGD = () => {

    const {authtoken,role} = useSelector(state => state.user);
    const alert = useAlert();

    const [processapp,setProcessapp] = useState(false);
    const [selectedapp,Selectedapp] = useState({});
    const [reportmode, setReportmode] = useState(false);

    if(processapp)
    {
        return(
            <AppointmentFlow Appointment={selectedapp} startingpage={2} close={()=>setProcessapp(false)} />
        )
    }

    return(
        <div className='w-full'>
            <div className='flex justify-end pr-28'>
                <button className="mr-28 border border-white rounded-lg p-2 text-white" onClick={()=>setReportmode(!reportmode)}>
                    {
                        reportmode
                        ?
                        "Normal View"
                        :
                        "Report View"
                    }
                </button>
            </div>
            {
                reportmode
                ?
                <Reports stage={2}/>
                :
                <AppointmentsList status={"InitDiag"} dodiagnose={setProcessapp} diagnoseappointment={Selectedapp} />
            }
        </div>
    )

    
}

export default FGD;