import { useEffect, useState } from 'react';

const Initialdiag = ({appointment}) => {

    //console.log(appointment);

    const [show,setShow] = useState(false);

    return(
        <div className="border rounded-lg p-2">
            <label className="text-2xl font-bold flex items-center justify-between">
                Initial Diagnosis
                <button className='' onClick={()=>setShow(!show)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" 
                        className={show?"w-6 h-6 rotate-90":"w-6 h-6"}
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </label>
            {
                show &&
                <div className='grid grid-cols-2 gap-2 mt-4'>
                    <label>
                        <span className="font-bold">
                            Weight :{" "}
                        </span>
                            {appointment.weight}
                            <span className="text-xs font-bold"> Kg</span>
                    </label>
                    <label>
                        <span className="font-bold">
                            Height :{" "}
                        </span>
                            {appointment.height}
                            <span className="text-xs font-bold"> cm</span>
                    </label>
                    <label>
                        <span className="font-bold">
                            Creatinine :{" "}
                        </span>
                            {appointment.creatinine}
                            <span className="text-xs font-bold"> mg/dl</span>
                    </label>
                    <label>
                        <span className="font-bold">
                            GRBS :{" "}
                        </span>
                            {appointment.grbs}
                            <span className="text-xs font-bold"> mg/dl</span>
                    </label>
                    {
                        appointment.sex === "Female" 
                        &&
                        <label>
                            <span className="font-bold">
                                LMB :{" "}
                            </span>
                                {appointment.lmb}
                        </label>
                    }
                </div>
            }
        </div>
    )
}

export default Initialdiag;