import { useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import Appointments from './Appointments';
import Patients from './Patients';
import Users from "./Users";
import InitialDiagnosis from "./InitialDiagnosis";
import FGD from "./FGD";
import Approvers from "./Approvers";
import Completed from "./Completed";
import Masters from "./Masters";

const Home = () =>{

    const { currentpage } = useSelector(state => state.user);

    return(
        <div className="w-full h-full scroll home flex flex-col bg-[#0A0145] box-border">
            <Navbar />
            <div className="h-full scroll box-border">
                {currentpage === "Appointments" && <Appointments></Appointments>}
                {currentpage === "Initdiag" && <InitialDiagnosis/>}
                {currentpage === "FGD" && <FGD/>}
                {currentpage === "Approvers" && <Approvers/>}
                {currentpage === "Completed" && <Completed/>}
                {currentpage === "Patients" && <Patients/>}
                {currentpage === "Users" && <Users/>}
                {currentpage === "Masters" && <Masters/>}
            </div>
        </div>
    )
}

export default Home;