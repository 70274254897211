import { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import AppointmentFlow from "./AppointmentFlow";
import AppointmentsList from '../components/AppointmentsList';

const Completed = () => {

    const {authtoken,role} = useSelector(state => state.user);
    const alert = useAlert();

    const [processapp,setProcessapp] = useState(false);
    const [selectedapp,Selectedapp] = useState({});

    if(processapp)
    {
        return(
            <AppointmentFlow Appointment={selectedapp} startingpage={4} close={()=>setProcessapp(false)} />
        )
    }

    return(
        <AppointmentsList status={"Approved"} dodiagnose={setProcessapp} diagnoseappointment={Selectedapp} />
    )

    
}

export default Completed;