import { useEffect, useState } from 'react';

const Approvers = ({appointment}) => {

    //console.log(appointment);

    const [show,setShow] = useState(false);

    return(
        <div className="border rounded-lg p-2">
            <label className="text-2xl font-bold flex items-center justify-between">
                Console Room
                <button className='' onClick={()=>setShow(!show)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" 
                        className={show?"w-6 h-6 rotate-90":"w-6 h-6"}
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </label>
            {
                show &&
                <div className='grid grid-cols-2 gap-2 mt-4'>
                    <label>
                        <span className="font-bold">
                            Scan-Start :{" "}
                        </span>
                            {appointment.scanstart}
                    </label>
                    <label>
                        <span className="font-bold">
                            Scan-End :{" "}
                        </span>
                            {appointment.scanend}
                    </label>
                    <label>
                        <span className="font-bold">
                            Approved By :{" "}
                        </span>
                        {appointment.approvedby}
                    </label>
                    
                </div>
            }
            {
                show
                &&
                <label className='mt-4'>
                    <span className="font-bold">
                        Remarks :{" "}
                    </span>
                    {appointment.approverremarks} 
                </label>
            }
        </div>
    )
}

export default Approvers;