import { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import updatejwt from '../utils/updatejwt';
import Patientinfo from '../components/PatientInfo';
import Initialdiag from '../components/Initialdiag';
import Fdg from '../components/fdg';
import Approvers from '../components/approvers';

const {login,ujwt,logout} = require('../features/user/userSlice');

const AppointmentFlow = ({Appointment,startingpage,close}) => {


    const stages = ["Patientinfo","InitDiag","FGD","Approvers"]

    const {authtoken,role,firstname,lastname} = useSelector(state => state.user);
    const dispatch = useDispatch();
    const alert = useAlert();

    const [appointment,setAppointment] = useState(Appointment);
    const [currstage,setCurrstage] = useState(startingpage);

    const [approverconfirmation,setApproverconfirmation] = useState(appointment.approvedby?true:false);

    const [doctors,setDoctors] = useState([]);

    //console.log(currstage);

    useEffect(()=>{
        
    },[])



    const validateappointment = () => {
        if((appointment.petcetid && appointment.uhid && appointment.mrnno
            && appointment.pname && appointment.phonenumber
            && appointment.age && appointment.sex && appointment.approxweight && appointment.doctorname
            && appointment.paymentinfo && appointment.datetime && appointment.doctorname))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const validateinitdiag = () => {
        if((appointment.weight && appointment.height && appointment.creatinine && appointment.grbs && (appointment.sex==="Male" || validatelmb())))
        {
            return true;
        }
        else
        {
            console.log("nurse val");
            return false;
        }
    }

    const validatelmb = () => {
        if((appointment.lmb))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const validatefgd = () => {
        if((appointment.preinjection && appointment.postinjection && validatebalancefdg() && validatebalancefdg()))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const validategrbs = () => {
        if((appointment.grbs && appointment.grbs >= 65 && appointment.grbs <= 250))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const validatecreatinine = () => {
        if((appointment.creatinine && appointment.creatinine >= 0 && appointment.creatinine <= 1.3))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const validatepostinjection = () => {
        if((appointment.preinjection && appointment.postinjection && appointment.postinjection < appointment.preinjection ))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const validatebalancefdg = () => {
        if((appointment.balancefdg))
        {
            let pattern = /^\d{1,3}\.\d{2}$/;
            let isValid = pattern.test(appointment.balancefdg);
            if(isValid)
            {
                return true;
            }
            return false;
        }
        else
        {
            return false;
        }
    }

    const validateapprover = () => {
        if(appointment.approverremarks && appointment.scanstart && appointment.scanend)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const handleupdate = async () => {

        let updated = await updatejwt();
        if(updated)
        {
            try
            {
                let logindetails = localStorage.getItem('kidwailogin');
                logindetails = await JSON.parse(logindetails);
                dispatch(ujwt(logindetails));
            }
            catch(error)
            {
                alert.error("Session Expired, Login Again");
                localStorage.clear();
                dispatch(logout());
                return;
            }
        }
        else
        {
            alert.error("Session Expired, Login Again");
            localStorage.clear();
            dispatch(logout());
            return;
        }

        let res;

        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_UPDATE_APPOINTMENT;
    
        //console.log(url);

        if(!validateappointment(appointment))
        {
            alert.error("Fill in all the details");
            return 
        }

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(appointment)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching appointments");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            alert.success("Data saved successfully!");
        }
    }

    const handleback = () => {
        if(currstage===0)
        {
            close();
        }
        else
        {
            setCurrstage(currstage-1);
        }
    }

    const handlesave = () => {
        if(currstage===0)
        {
            if(validateappointment())
            {
                handleupdate();
            }
            else
            {
                alert.error("Fill all the details.")
            }
        }
        else if(currstage===1)
        {
            if(validateinitdiag())
            {
                appointment.currstage = "InitDiag"
                appointment.valnurse = true;
                setAppointment({...appointment});
                handleupdate();
            }
            else
            {
                alert.error("Fill all the details.")
            }
            
        }
        else if(currstage===2)
        {
            //"2023-04-30T13:00:00.000Z"
            if(validatefgd())
            {
                console.log("FGD")
                appointment.currstage = "FGD"
                
                const date = new Date();
                const localISOTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
                if(!appointment.injectiontime)
                {
                    appointment.injectiontime = localISOTime;
                    console.log(appointment.injectiontime);
                }
                setAppointment({...appointment});
                handleupdate();
            }
            else
            {
                alert.error("Fill all the details.")
            }
        }
        else if(currstage===3)
        {
            if(validateapprover())
            {
                if(approverconfirmation)
                {
                    if(appointment.approvedby && role !== "Admin")
                    {
                        alert.error("You don't have permission to change the comment, Contact your Admin.");
                    }
                    else
                    {
                        appointment.currstage = "Approved"
                        appointment.approvedby = firstname + " " + lastname
                        setAppointment({...appointment});
                        handleupdate();
                    }
                }
                else
                {
                    alert.info("Please agree to the declaration.")
                }
            }
            else
            {
                alert.error("Fill all the details.")
            }
        }
    }

    const handlenext = () => {
        if(currstage===0)
        {
            if(role === 'Receptionist' )
            {
                alert.info("You Don't have enough access.")
            }
            else
            {
                if(!validateappointment())
                {
                    alert.info("Fill all the details.")
                }
                else
                {
                    setCurrstage(currstage+1)
                }
            }
        }
        else if(currstage===1)
        {
            if(role === 'Nurse' )
            {
                alert.info("You Don't have enough access.")
            }
            else
            {
                console.log("next nurse");
                if(!validateinitdiag())
                {
                    alert.info("Fill all the details.")
                }
                else
                {
                    setCurrstage(currstage+1)
                    // if(appointment.valnurse)
                    // {
                    // }
                    // else
                    // {
                    //     alert.info("Save before you continue.")
                    // }
                }
            }
        }
        else if(currstage===2)
        {
            if(appointment.injectiontime)
            {
                if(role === 'FGD' )
                {
                    alert.info("You Don't have enough access.")
                }
                else
                {
                    if(!validatefgd())
                    {
                        alert.info("Fill all the details.")
                    }
                    else
                    {
                        setCurrstage(currstage+1)
                        
                    }
                }
            }
            else
            {
                alert.info("Save before you continue.")
            }
        }
        else if(currstage===3)
        {
            if(appointment.approvedby)
            {
                if(role === 'Doctor' )
                {
                    alert.info("You Don't have enough access.")
                }
                else
                {
                    setCurrstage(currstage+1)
                }
            }
            else
            {
                alert.info("Save before you continue.")
            }
        }
        else
        {
            close();
        }
    }

    const handlereject =  async () => {
        let updateappointment = {...appointment}
        // console.log(appointment);
        updateappointment.currstage = "Rejected"

        let res;

        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_UPDATE_APPOINTMENT;

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(updateappointment)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured rejecting patient.");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            alert.success("Patient Rejected.");
            close();
        }
    }

    return(
        <div className="flex flex-col justify-center content-center items-center w-full h-full">
            <div className="bg-white h-fit w-fit p-4 rounded-xl mt-20">
                <div className='flex justify-end mb-4 mr-2'>
                    <button onClick={close}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" className="w-6 h-6" strokeWidth="8">
                            <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
                {
                    currstage === 0
                    &&
                    <div>
                        <label className='flex items-center justify-center font-bold m-2 mb-6 text-3xl'>Patient Information</label>
                        <div className='flex py-1'>
                        <label className='flex items-center w-[180px]'>PETCET ID : </label>
                        <input type="text" name="petcetid" 
                            className='text-black border border-black p-1 rounded-lg w-[250px]'
                            value={appointment.petcetid} 
                            onChange={(e)=>{
                                appointment.petcetid = e.target.value;
                                setAppointment({...appointment});
                            }}
                        />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[180px]'>UHID : </label>
                            <input type="text" name="uhid" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.uhid} 
                                onChange={(e)=>{
                                    appointment.uhid = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[180px]'>MRNNO: </label>
                            <input type="text" name="mrnno" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.mrnno} 
                                onChange={(e)=>{
                                    appointment.mrnno = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Patient Name : </label>
                            <input type="text" name="pname" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.pname} 
                                onChange={(e)=>{
                                    appointment.pname = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Phone Number : </label>
                            <input type="number" name="phonenumber" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.phonenumber} 
                                onChange={(e)=>{
                                    appointment.phonenumber = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Age : </label>
                            <input type="number" name="age" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.age} 
                                onChange={(e)=>{
                                    appointment.age = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Sex : </label>
                            <select value={appointment.sex} 
                                className='text-black border border-black p-1 rounded-lg w-[250px] h-[38px]'
                                onChange={(e)=>{
                                    appointment.sex = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            >
                                <option value="">Select a Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Approximate Weight : </label>
                            <input type="number" name="approxweight" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.approxweight} 
                                onChange={(e)=>{
                                    appointment.approxweight = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Doctor : </label>
                            <input type="text" name="doctorname" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.doctorname} 
                                onChange={(e)=>{
                                    appointment.doctorname = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Note : </label>
                            <textarea type="text" name="note" rows="6" cols="30"
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.note}
                                readOnly={true}
                            />
                        </div>
                    </div>
                }
                {
                    currstage === 1
                    &&
                    <div>
                        <Patientinfo appointment={appointment}/>
                        <label className='flex items-center justify-center font-bold m-2 mb-6 text-3xl'>Initial Diagnosis</label>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[120px]'>Weight : </label>
                            <input type="number" name="weight" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.weight} 
                                onChange={(e)=>{
                                    appointment.weight = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                            <span>kg</span>
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[120px]'>Height : </label>
                            <input type="number" name="height" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.height} 
                                onChange={(e)=>{
                                    appointment.height = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                            <span>cm</span>
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[120px]'>Creatinine: </label>
                            <input type="number" name="creatinine" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.creatinine} 
                                onChange={(e)=>{
                                    appointment.creatinine = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                            <span>mg/dl</span>
                        </div>
                        <span className="text-red-700 text-sm flex items-center ml-2 w-[300px] basis-full">
                            {
                                (appointment.creatinine===undefined)||(validatecreatinine())
                                ?
                                ""
                                :
                                "Creatinine should be between 0 and 1.3."
                            }
                        </span>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[120px]">GRBS : </label>
                            <input type="number" name="grbs" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.grbs} 
                                onChange={(e)=>{
                                    appointment.grbs = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                            <span>mg/dl</span>
                        </div>
                        <span className="text-red-700 text-sm flex items-center ml-2 w-[300px] basis-full">
                            {
                                (appointment.grbs===undefined)||(validategrbs())
                                ?
                                ""
                                :
                                "GRBS should be between 65 and 250."
                            }
                        </span>
                        {
                            appointment.sex === "Female" 
                            &&
                            <div className='flex py-1'>
                                <label className="flex items-center w-[120px]">LMB : </label>
                                <input type="text" name="lmb" 
                                    className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                    value={appointment.lmb} 
                                    onChange={(e)=>{
                                        appointment.lmb = e.target.value;
                                        setAppointment({...appointment});
                                    }}
                                />
                                {/* <span>mg/dl</span> */}
                            </div>
                        }
                        <div className='flex py-1'>
                            <label className="flex items-center w-[120px]">Note : </label>
                            <textarea type="text" name="note" rows="6" cols="50"
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.note}
                                readOnly={true}
                            />
                        </div>
                        <div className='flex py-1 hidden'>
                            <input type="text" name="valnurse" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.valnurse} 
                                
                            />
                        </div>
                    </div>
                }
                {
                    currstage === 2
                    &&
                    <div>
                        <Patientinfo appointment={appointment}/>
                        <Initialdiag appointment={appointment}/>
                        <label className='flex items-center justify-center m-2 mb-6 text-3xl font-bold'>FDG</label>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[120px]'>Pre-Injection : </label>
                            <input type="text" name="preinjection" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.preinjection} 
                                onChange={(e)=>{
                                    appointment.preinjection = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                            <span>mci</span>
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[120px]'>Post-Injection : </label>
                            <input type="text" name="postinjection" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.postinjection} 
                                onChange={(e)=>{
                                    appointment.postinjection = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                            <span>mci</span>
                        </div>
                        <span className="text-red-700 text-sm flex items-center ml-2 w-[380px] basis-full">
                            {
                                (appointment.postinjection===undefined)||(validatepostinjection())
                                ?
                                ""
                                :
                                "Post-Injection should be less than Pre-Injection."
                            }
                        </span>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[120px]'>Balance FDG : </label>
                            <input type="text" name="balancefdg" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.balancefdg} 
                                onChange={(e)=>{
                                    appointment.balancefdg = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                            <span>suv</span>
                        </div>
                        <span className="text-red-700 text-sm flex items-center ml-2 w-[380px] basis-full">
                            {
                                (appointment.balancefdg===undefined)||(validatebalancefdg())
                                ?
                                ""
                                :
                                "Please enter three digits followed by two decimal points."
                            }
                        </span>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[120px]'>Injection Time: </label>
                            <input type="text" name="injectiontime" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.injectiontime ? appointment.injectiontime.slice(11,19):""} 
                                readOnly={true}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[120px]">Note : </label>
                            <textarea type="text" name="note" rows="6" cols="50"
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.note}
                                readOnly={true}
                            />
                        </div>
                    </div>
                }
                {
                    currstage === 3
                    &&
                    <div>
                        <Patientinfo appointment={appointment}/>
                        <Initialdiag appointment={appointment}/>
                        <Fdg appointment={appointment}/>
                        <label className='flex items-center justify-center m-2 mb-6 text-3xl font-bold'>Console Room</label>                        
                        <div className='flex py-1'>
                            <label className='flex items-center w-[110px]'>Scan-Start : </label>
                            <input type="text" name="scanstart" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.scanstart} 
                                onChange={(e)=>{
                                    appointment.scanstart = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[110px]'>Scan-End : </label>
                            <input type="text" name="scanend" 
                                className='text-black border border-black p-1 mr-1 rounded-lg w-[250px]'
                                value={appointment.scanend} 
                                onChange={(e)=>{
                                    appointment.scanend = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[110px]'>Remarks: </label>
                            <textarea type="text" name="approverremarks" rows="6" cols="80"
                                className='text-black border border-black p-1 rounded-lg'
                                value={appointment.approverremarks} 
                                onChange={(e)=>{
                                    appointment.approverremarks = e.target.value;
                                    setAppointment({...appointment});
                                }}
                                readOnly={appointment.approvedby?(role==="Admin"?false:true):false}
                            />
                        </div>
                        <div className='p-1 mt-2'>
                            <label className='p-1 mt-2 font-bold'>
                                <input type="checkbox" 
                                    className='' 
                                    checked={approverconfirmation} 
                                    onChange={()=>setApproverconfirmation(!approverconfirmation)} 
                                    readOnly={appointment.approvedby?(role==="Admin"?false:true):false}
                                />
                                {" "}I hereby confirm that all the above tests and information are as per the requirement.
                            </label>
                        </div>
                        <div className='flex justify-center py-1'>
                            <label className='flex items-center w-[110px]'>Approved By: </label>
                            <input type="text" name="preinjection" 
                                className='text-black border border-black p-1 mr-1 rounded-lg'
                                value={appointment.approvedby?appointment.approvedby:""} 
                                readOnly={true}
                            />
                        </div>
                    </div>
                }
                {
                    currstage === 4
                    &&
                    <div>
                        <Patientinfo appointment={appointment}/>
                        <Initialdiag appointment={appointment}/>
                        <Fdg appointment={appointment}/>
                        <Approvers appointment={appointment}/>
                    </div>
                }
                {
                    (currstage === 1 && (appointment.currstage === "InitDiag" || appointment.currstage === "Patientinfo"))
                    &&
                    <div className="flex w-full justify-center gap-8 mt-8">
                        <button onClick={handlereject} className="w-32 box-border relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-[#9A031E] rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                        <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="relative z-20 flex items-center text-sm">
                            Reject Patient
                        </span>
                    </button>
                    </div>
                }
                <div className="flex w-full justify-center gap-8 mt-8">
                    <button onClick={handleback} className="w-32 box-border relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-[#9A031E] rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                        <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="relative z-20 flex items-center text-sm">
                        {currstage===0?"Cancel":"Back"}
                        </span>
                    </button>

                    {
                        currstage !== 4
                        &&
                        <button onClick={handlesave} className="w-32 box-border relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-purple-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-purple-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                            <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span className="relative z-20 flex items-center text-sm">
                                Save
                            </span>
                        </button>
                    }
                    
                    {
                        (currstage === 4 || (currstage === 3 && role === "Doctor") || (currstage === 2 && role === "FGD") || (currstage === 1 && role === "Nurse") || (currstage === 0 && role === "Receptionist"))
                        ?
                        <button onClick={close} className="w-32 box-border relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-[#9A031E] rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                            <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span className="relative z-20 flex items-center text-sm">
                                Close
                            </span>
                        </button>
                        :
                        <button onClick={handlenext} className="w-32 box-border relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-[#9A031E] rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                            <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                            <span className="relative z-20 flex items-center text-sm">
                                Next
                            </span>
                        </button>
                    }
                </div>
            </div>
            <br></br>
        </div>
    )

}

export default AppointmentFlow;