

const updatejwt = async () => {


    const handlelogout = () => {
        localStorage.clear();
    }

    let res;
    let logindetails = localStorage.getItem('kidwailogin');
    logindetails = await JSON.parse(logindetails);
    if(!(logindetails===null))   
    {
        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_JWTVAL;

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + logindetails.authtoken,
                }
            })
            
            res = await res.json();
        }
        catch(error)
        {
            console.log(error);
            handlelogout();
            return false;
        }

        if(res.status === "Failure")
        {
            handlelogout();
        }
        else if(res.status === "Success")
        {
            logindetails.authtoken = res.token;
            //console.log(logindetails);
            localStorage.setItem('kidwailogin', JSON.stringify(logindetails));
            return true;
        }
        return false;
    
    }
}

export default updatejwt;