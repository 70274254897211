import { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import updatejwt from '../utils/updatejwt';

const {login,ujwt,logout} = require('../features/user/userSlice');

const CreateAppointment = ({cancel,editappointment}) => {

    //console.log(editappointment);

    const {authtoken,role} = useSelector(state => state.user);
    const alert = useAlert();
    const dispatch = useDispatch();

    const stages = ["payment","patient","date","time"];

    const [currentStage,setCurrentStage] = useState(0);

    const [appointment,setAppointment] = useState(editappointment?editappointment:{});

    const [date,setDate] = useState(editappointment?editappointment.datetime.slice(0,10):"");
    const [time,setTime] = useState(editappointment?editappointment.datetime.slice(11,19):"");

    const timings = ["07:00:00","08:00:00","09:00:00","10:00:00","11:00:00","12:00:00","13:00:00","14:00:00","15:00:00","16:00:00","17:00:00","18:00:00","19:00:00"];

    //console.log(date+"T"+time+".000Z");

    const validateappointment = (appointment) => {
        if(( appointment.uhid && appointment.mrnno
            && appointment.pname && appointment.phonenumber
            && appointment.age && appointment.sex && appointment.approxweight && appointment.doctorname
            && appointment.paymentinfo && date!=="" && time !== ""))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const validateUHID = (uhid) => {
        //console.log("UHID ",uhid);
        if(uhid===undefined)
            return false;

        const pattern = /^\d{11}$/;
        if (pattern.test(uhid)) 
        {
            return true;
        } 
        else 
        {
            return false;
        }
    }

    const validateMRNNO = (mrnno) => {

        if(mrnno===undefined)
            return false;

        const pattern = /^\d{3,5}\/\d{4}$/;
        if (pattern.test(mrnno)) 
        {
            return true;
        } 
        else 
        {
            return false;
        }
    }

    const handlecreate = async () => {

        let updated = await updatejwt();
        if(updated)
        {
            try
            {
                let logindetails = localStorage.getItem('kidwailogin');
                logindetails = await JSON.parse(logindetails);
                dispatch(ujwt(logindetails));
            }
            catch(error)
            {
                alert.error("Session Expired, Login Again");
                localStorage.clear();
                dispatch(logout());
                return;
            }
        }
        else
        {
            alert.error("Session Expired, Login Again");
            localStorage.clear();
            dispatch(logout());
            return;
        }

        let res;

        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_CREATE_APPOINTMENT;

        if(editappointment)
        {
            url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_UPDATE_APPOINTMENT;
        }
        else
        {
            appointment.currstage = "Patientinfo";
        }

        //console.log(url);

        if(!validateappointment(appointment))
        {
            alert.error("Fill in all the details");
            return 
        }

        appointment.datetime = date+"T"+time+".000Z" ;

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(appointment)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when creating appointment");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            if(editappointment)
                alert.success("appointment updated successfully");
            else
                alert.success("appointment created successfully");
            cancel();
        }
    }

    const handleback = () => {
        if(currentStage===0)
        {
            cancel();
        }
        else
        {
            setCurrentStage(currentStage-1);
        }
    }

    const handlenext = () => {
        if(currentStage===0)
        {   
            if((appointment.paymentinfo===undefined || appointment.paymentinfo===""))
            {
                
                alert.error("Please select a payment type.");
            }
            else
            {
                setCurrentStage(currentStage+1);
            }
        }
        else if(currentStage===1)
        {
            if(!validateUHID(appointment.uhid) && !validateMRNNO(appointment.mrnno))
            {
                alert.error("Fill data in proper format");
            }
            else
            {
                setCurrentStage(currentStage+1);
            }
        }
        else if(currentStage===2)
        {
            handlecreate();
        }
        else
        {
            setCurrentStage(currentStage+1);
        }
    }

  
    return(
        <div className="flex flex-col justify-center items-center w-full h-full box-border">
            <div className="bg-white h-fit w-fit p-4 rounded-xl mt-10">
                {
                    currentStage === 0
                    &&
                    <div className="h-fit w-fit">
                        <div className='py-1'>
                            <label className='flex items-center justify-center m-2 mb-6 text-3xl font-bold'>Payment Type</label>
                            <select value={appointment.paymentinfo} 
                                className='text-black border border-black p-1 rounded-lg mb-6 h-[40px] w-[240px]'
                                onChange={(e)=>{
                                    appointment.paymentinfo = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            >
                                <option value="">Select a Type</option>
                                <option value="Cash">Cash</option>
                                <option value="Schemes">Schemes</option>
                                {
                                    role === 'Admin'
                                    &&
                                    <option value="FOC (Free of cost)">FOC (Free of cost)</option>
                                }
                            </select>
                        </div>
                    </div>
                }
                
                {
                    currentStage === 1
                    &&
                    <div className="">
                        <label className='flex items-center justify-center m-2 mb-6 text-3xl font-bold'>Patient Information</label>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[180px]'>PETCET ID : </label>
                            <input type="text" name="petcetid" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.petcetid} 
                                // onChange={(e)=>{
                                //     appointment.petcetid = e.target.value;
                                //     setAppointment({...appointment});
                                // }}
                                readOnly={true}
                                placeholder='ID is Autogenerated'
                            />
                        </div>

                        <div className='flex py-1'>
                            <label className='flex items-center w-[180px]'>UHID : </label>
                            <div>
                                <input type="text" name="uhid" 
                                    className='text-black border border-black p-1 rounded-lg w-[250px]'
                                    value={appointment.uhid} 
                                    onChange={(e)=>{
                                        appointment.uhid = e.target.value;
                                        setAppointment({...appointment});
                                    }}
                                />
                                <span className="text-red-700 text-sm flex items-center ml-2 w-[180px]">
                                    {
                                        (appointment.uhid===undefined)||(validateUHID(appointment.uhid))
                                        ?
                                        ""
                                        :
                                        "Not a valid UHID."
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[180px]'>MRNNO: </label>
                            <div>
                                <input type="text" name="mrnno" 
                                    className='text-black border border-black p-1 rounded-lg w-[250px]'
                                    value={appointment.mrnno} 
                                    onChange={(e)=>{
                                        appointment.mrnno = e.target.value;
                                        setAppointment({...appointment});
                                    }}
                                />
                                <span className="text-red-700 text-sm flex items-center ml-2 w-[180px]">
                                    {
                                        (appointment.mrnno===undefined)||(validateMRNNO(appointment.mrnno))
                                        ?
                                        ""
                                        :
                                        "Not a valid MRNNO."
                                    }
                                </span>
                            </div>
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Patient Name : </label>
                            <input type="text" name="pname" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.pname} 
                                onChange={(e)=>{
                                    appointment.pname = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Phone Number : </label>
                            <input type="number" name="phonenumber" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.phonenumber} 
                                onChange={(e)=>{
                                    appointment.phonenumber = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Age : </label>
                            <input type="number" name="age" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.age} 
                                onChange={(e)=>{
                                    appointment.age = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Sex : </label>
                            <select value={appointment.sex} 
                                className='text-black border border-black p-1 rounded-lg w-[250px] h-[38px]'
                                onChange={(e)=>{
                                    appointment.sex = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            >
                                <option value="">Select a Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Approximate Weight : </label>
                            <input type="number" name="approxweight" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.approxweight} 
                                onChange={(e)=>{
                                    appointment.approxweight = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Doctor : </label>
                            <input type="text" name="doctorname" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.doctorname} 
                                onChange={(e)=>{
                                    appointment.doctorname = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Department : </label>
                            <input type="text" name="docdepartment" 
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.docdepartment} 
                                onChange={(e)=>{
                                    appointment.docdepartment = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[180px]">Note : </label>
                            <textarea type="text" name="note" rows="6" cols="30"
                                className='text-black border border-black p-1 rounded-lg w-[250px]'
                                value={appointment.note} 
                                onChange={(e)=>{
                                    appointment.note = e.target.value;
                                    setAppointment({...appointment});
                                }}
                            />
                        </div>
                    </div>
                }

                {
                    currentStage === 2
                    &&
                    <div>
                        <label className='flex items-center justify-center mr-4 m-2 mb-6 text-3xl font-bold'>Select a Date</label>
                        <input type="date" name="startdate" 
                            className='text-black border border-black p-1 px-2 rounded-lg m-4 mb-6 w-[260px]'
                            value={date} 
                            onChange={(e)=>{
                                setDate(e.target.value);
                            }}
                        />
                        <label className='flex items-center justify-center m-2 mb-6 text-3xl font-bold'>Select a Time</label>
                        <div className=" grid grid-cols-3 gap-4 mb-6">
                        {
                            timings.map((t)=>{
                                return(
                                    <button 
                                        className={
                                            time===t
                                            ?
                                            "p-2 border bg-blue-900 text-white border-black rounded-xl"
                                            :
                                            "p-2 border border-black rounded-xl"
                                        }
                                        onClick={()=>{setTime(t)}}
                                    >{t}</button>
                                    )
                                })
                        }
                        </div>
                    </div>
                }
                
                <div className="flex justify-center gap-8 mt-8">
                    <button onClick={handleback} className="w-32 box-border relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-[#9A031E] rounded-md cursor-pointer group ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                        <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="relative z-20 flex items-center text-sm">
                            {currentStage===0?"Cancel":"Back"}
                        </span>
                    </button>
                    <button onClick={handlenext} className="w-32 box-border relative z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-300 bg-purple-600 rounded-md cursor-pointer group ring-offset-2 ring-1 ring-purple-300 ring-offset-indigo-200 hover:ring-offset-indigo-500 ease focus:outline-none">
                        <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-1 bg-white opacity-10 group-hover:translate-x-0"></span>
                        <span className="relative z-20 flex items-center text-sm">
                            {currentStage!==2?"Next":"Create"}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CreateAppointment;