import { ColumnDirective, ColumnsDirective, TreeGridComponent } from '@syncfusion/ej2-react-treegrid';
import { useEffect } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
const {useState} = require('react');

const Patients = () => {
    
    const alert = useAlert();

    const [spname,setSpname] = useState("");
    const [sphno,setSphno] = useState("");

    const [patientlist,setPatientlist] = useState([]);

    const [docreate,setDocreate] = useState(false);
    const [doedit,setDoedit] = useState(false);

    const [createpatient,setCreatepatient] = useState({});
    const [editpatient,setEditpatient] = useState({});

    const {authtoken} = useSelector(state => state.user);

    useEffect(()=>{
        getpatients();
    },[])

    const validatepatient = (patient) => {
        if((patient.petcetid && patient.uhid && patient.mrnno
            && patient.pname && patient.phonenumber
            && patient.age && patient.sex && patient.approxweight))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const getpatients = async () => {
        
        let res;
        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_LIST_PATIENT;

        if(spname !== "" && sphno !== "")
        {
            url += `?pname=${spname}&phonenumber=${sphno}`
        }
        else if(spname !== "")
        {
            url += `?pname=${spname}`
        }
        else if(sphno !== "")
        {
            url += `?phonenumber=${sphno}`
        }

        console.log(url);

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken
                }
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching patients");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            setPatientlist(res.data);
        }
    }

    const handleedit = async (e) => {
        e.preventDefault();
        console.log("editing");
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_UPDATE_PATIENT;

        //console.log(url);

        if(!validatepatient(editpatient))
        {
            alert.error("Fill in all the details");
            return 
        }

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"PUT",
                body:JSON.stringify(editpatient)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching patients");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            getpatients();
            alert.success("Patient updated successfully");
            setDoedit(false);
        }
    }

    const handlecreate = async (e) => {
        e.preventDefault();
        console.log("editing");
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_CREATE_PATIENT;

        //console.log(url);

        if(!validatepatient(createpatient))
        {
            alert.error("Fill in all the details");
            return 
        }

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(createpatient)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching patients");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            setPatientlist([res.data[0],...patientlist]);
            alert.success("patient created successfully");
            setDocreate(false);
        }
    }

    const handleeditcancel = async (e) => {
        e.preventDefault();
        setDoedit(false);
    }

    const handlecreatecancel = async (e) => {
        e.preventDefault();
        setDocreate(false);
    }
    //console.log(editpatient);

    if(docreate)
    {
        return(
            <div className='w-full h-full flex justify-center pt-12'>
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    <form onSubmit={(e) => handlecreate(e)}>

                        <div className='flex py-1'>
                            <label className='flex items-center w-[125px]'>PETCET ID : </label>
                            <input type="text" name="petcetid" 
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.petcetid} 
                                onChange={(e)=>{
                                    createpatient.petcetid = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[125px]'>UHID : </label>
                            <input type="text" name="uhid" 
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.uhid} 
                                onChange={(e)=>{
                                    createpatient.uhid = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[125px]'>MRNNO: </label>
                            <input type="text" name="mrnno" 
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.mrnno} 
                                onChange={(e)=>{
                                    createpatient.mrnno = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Patient Name : </label>
                            <input type="text" name="pname" 
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.pname} 
                                onChange={(e)=>{
                                    createpatient.pname = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Phone Number : </label>
                            <input type="number" name="phonenumber" 
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.phonenumber} 
                                onChange={(e)=>{
                                    createpatient.phonenumber = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Age : </label>
                            <input type="number" name="age" 
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.age} 
                                onChange={(e)=>{
                                    createpatient.age = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Sex : </label>
                            <select value={createpatient.sex} 
                                className='text-black border p-1 rounded-lg'
                                onChange={(e)=>{
                                    createpatient.sex = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            >
                                <option value="">Select a Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Approximate Weight : </label>
                            <input type="number" name="approxweight" 
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.approxweight} 
                                onChange={(e)=>{
                                    createpatient.approxweight = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Note : </label>
                            <textarea type="text" name="note" rows="4" cols="50"
                                className='text-black border p-1 rounded-lg'
                                value={createpatient.note} 
                                onChange={(e)=>{
                                    createpatient.note = e.target.value;
                                    setCreatepatient({...createpatient});
                                }}
                            />
                        </div>
                        <div className='flex justify-around pt-6'>
                            <button type="submit" className='border border-2 border-black rounded-xl p-2'>
                                Create
                            </button>
                            <button onClick={handlecreatecancel} className='border border-2 border-black rounded-xl p-2'>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    if(doedit)
    {
        return(
            <div className='w-full h-full flex justify-center pt-12'>
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    <form onSubmit={(e) => handleedit(e)}>

                        <div className='flex py-1'>
                            <label className='flex items-center w-[125px]'>PETCET ID : </label>
                            <input type="text" name="petcetid" 
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.petcetid} 
                                onChange={(e)=>{
                                    editpatient.petcetid = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[125px]'>UHID : </label>
                            <input type="text" name="uhid" 
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.uhid} 
                                onChange={(e)=>{
                                    editpatient.uhid = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[125px]'>MRNNO: </label>
                            <input type="text" name="mrnno" 
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.mrnno} 
                                onChange={(e)=>{
                                    editpatient.mrnno = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Patient Name : </label>
                            <input type="text" name="pname" 
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.pname} 
                                onChange={(e)=>{
                                    editpatient.pname = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Phone Number : </label>
                            <input type="number" name="phonenumber" 
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.phonenumber} 
                                onChange={(e)=>{
                                    editpatient.phonenumber = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Age : </label>
                            <input type="number" name="age" 
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.age} 
                                onChange={(e)=>{
                                    editpatient.age = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Sex : </label>
                            <select value={editpatient.sex} 
                                className='text-black border p-1 rounded-lg'
                                onChange={(e)=>{
                                    editpatient.sex = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            >
                                <option value="">Select a Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Approximate Weight : </label>
                            <input type="number" name="approxweight" 
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.approxweight} 
                                onChange={(e)=>{
                                    editpatient.approxweight = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[125px]">Note : </label>
                            <textarea type="text" name="note" rows="4" cols="50"
                                className='text-black border p-1 rounded-lg'
                                value={editpatient.note} 
                                onChange={(e)=>{
                                    editpatient.note = e.target.value;
                                    setEditpatient({...editpatient});
                                }}
                            />
                        </div>
                        <div className='flex justify-around pt-6'>
                            <button type="submit" className='border border-2 border-black rounded-xl p-2'>
                                Update
                            </button>
                            <button onClick={handleeditcancel} className='border border-2 border-black rounded-xl p-2'>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='w-full mt-8 ml-8 text-white'>
                <button 
                    className='p-2 border border-2 border-white rounded-xl'
                    onClick={() => setDocreate(true)}
                >
                    Add patient
                </button>
            </div>

            <div className='mt-5 w-full flex justify-center'>
                <div className='w-fit flex flex-row items-center gap-8 bg-white p-8 border border-black border-2 rounded-xl'>
                    <div>
                        <label className='flex items-center w-[125px]'>Patient Name : </label>
                        <input type="text" name="pname" 
                            className='text-black border border-black p-1 px-2 rounded-lg'
                            value={spname} 
                            onChange={(e)=>{
                                setSpname(e.target.value);
                            }}
                        />
                    </div>

                    <div>
                        <label className='flex items-center w-[125px]'>Phone Number : </label>
                        <input type="text" name="sphno" 
                            className='text-black border border-black p-1 px-2 rounded-lg'
                            value={sphno} 
                            onChange={(e)=>{
                                setSphno(e.target.value);
                            }}
                        />
                    </div>

                    <div>
                        <button className='border border-black p-2 rounded-xl text-white bg-blue-900' onClick={getpatients}>
                            Search
                        </button>
                    </div>
                </div>
                
            </div>

        
            <div className='w-full h-full flex justify-center pt-12'>
                
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    
                    <table className="border-collapse border border-slate-500 text-center">
                        <thead>
                            <tr>
                                <th class="border border-slate-600 p-3">Patient Name</th>
                                <th class="border border-slate-600 p-3">Phone Number</th>
                                <th class="border border-slate-600 p-3">PETCET ID</th>
                                <th class="border border-slate-600 p-3">UHID</th>
                                <th class="border border-slate-600 p-3">MRNNO</th>
                                <th class="border border-slate-600 p-3">AGE</th>
                                <th class="border border-slate-600 p-3">SEX</th>
                                <th class="border border-slate-600 p-3">Details</th>
                                <th class="border border-slate-600 p-3">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                patientlist.map((patient, index) => {
                                    return (
                                        <tr key={index}>
                                            <td class="border border-slate-700 p-1">{patient.pname}</td>
                                            <td class="border border-slate-700 p-1">{patient.phonenumber}</td>
                                            <td class="border border-slate-700 p-1">{patient.petcetid}</td>
                                            <td class="border border-slate-700 p-1">{patient.uhid}</td>
                                            <td class="border border-slate-700 p-1">{patient.mrnno}</td>
                                            <td class="border border-slate-700 p-1">{patient.age}</td>
                                            <td class="border border-slate-700 p-1">{patient.sex}</td>
                                            <td class="border border-slate-700">
                                                <button 
                                                    onClick={()=>{setEditpatient(patient);setDoedit(true);}}
                                                    className='w-full h-full flex justify-center'
                                                >
                                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="blue" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />    
                                                    </svg>
                                                </button>
                                            </td>
                                            <td class="border border-slate-700">
                                                <button 
                                                    onClick={()=>{setEditpatient(patient);setDoedit(true);}}
                                                    className='w-full h-full flex justify-center'
                                                >
                                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                </svg>

                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </>
    )
}

export default Patients;