import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    firstname: "",
    lastname:"",
    email: "",
    role: "",
    authtoken: "",
    isloggedin: false,

    currentpage: "Appointments",
};

const userSlice =  createSlice({
    name:"user",
    initialState,
    reducers:{
        login:(state,{ payload }) => {
            state.firstname = payload.firstname;
            state.lastname = payload.lastname;
            state.email = payload.email;
            state.authtoken = payload.authtoken;
            state.role = payload.role;
            state.isloggedin = payload.isloggedin;
            state.currentpage = payload.role==="Admin"
                ?"Appointments"
                :(payload.role==="Receptionist"
                    ?"Appointments"
                    :(
                        payload.role==="Nurse"?
                        "Initdiag"
                        :(
                            payload.role==="FGD"?
                            "FGD"
                            :(
                                payload.role==="Doctor"?
                                "Approvers"
                                :
                                "Appointments"
                            )
                        )
                    )
                )
        },
        ujwt:(state,{ payload }) => {
            state.firstname = payload.firstname;
            state.lastname = payload.lastname;
            state.email = payload.email;
            state.authtoken = payload.authtoken;
            state.role = payload.role;
            state.isloggedin = payload.isloggedin;
            
        },
        logout:(state)=>{
            state.firstname = "";
            state.lastname = "";
            state.email = "";
            state.authtoken = "";
            state.role = "";
            state.isloggedin = false;
            state.currentpage = "Appointments";
        },
        setpage:(state,{payload}) => {
            state.currentpage = payload.page;
        }
    }
});

export const { 
        login,
        ujwt,
        setpage,
        logout
    } = userSlice.actions;

export default userSlice.reducer;