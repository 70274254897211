import { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import updatejwt from '../utils/updatejwt';
import { ExcelExport,PdfExport,Toolbar } from '@syncfusion/ej2-react-grids';

const {login,ujwt,logout} = require('../features/user/userSlice');

const Reports = ({stage}) => {
    const alert = useAlert();
    const dispatch = useDispatch();

    const date = new Date();
    const localtime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    //console.log("local time ",localtime);
    const now =  localtime.slice(0, 16);
    const [startdate, setStartdate ]  = useState(now);
    const [enddate, setEnddate ]  = useState(now);

    //console.log(startdate);

    const [appointmentlist,setAppointmentlist] = useState([]);

    // Day,TimeRange
    const [searchby,setSearchby] = useState("Day")

    const timings = ["07:00:00","08:00:00","09:00:00","10:00:00","11:00:00","12:00:00","13:00:00","14:00:00","15:00:00","16:00:00","17:00:00","18:00:00","19:00:00"];
    const [searchdate,setSearchdate] = useState(new Date().toISOString().slice(0,10));

    const {authtoken} = useSelector(state => state.user);

    useEffect(()=>{
        getappointments(true);
    },[])

    const getappointments = async (alltime=false) => {

        let updated = await updatejwt();
        if(updated)
        {
            let logindetails = localStorage.getItem('kidwailogin');
            logindetails = await JSON.parse(logindetails);
            dispatch(ujwt(logindetails));
        }
        else
        {
            alert.error("Session Expired, Login Again");
            dispatch(logout());
            return;
        }
        
        let res;
        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_LIST_APPOINTMENT;
        let body={};

        if(startdate !== "" && enddate !== "")
        {
            if(searchby === "Day")
            {
                body = {
                    "startdt" : searchdate+"T"+"00:00:00.000Z",
                    "enddt" : searchdate+"T"+"23:00:00.000Z"
                }
            }
            else
            {
                body = {
                    "startdt" : startdate+":00.000Z",
                    "enddt" : enddate+":00.000Z"
                }
            }
        }

        if(alltime===true)
        {
            body = {
                "startdt" : "2020-04-30T13:00:00.000Z",
                "enddt" : localtime
            }
        }

        //console.log(body);
        //console.log(url);

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(body)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching appointments");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            if(stage === 0)
            {
                // All appointments
            }
            else if(stage === 1)
            {
                res.data = await res.data.filter((a)=>{
                    //console.log(a.currstage,status);
                    return a.attendance === "Present" && (a.currstage === "Rejected" || a.currstage === "Approved" || a.currstage === "FGD" || a.currstage === "InitDiag") ;
                })
                //console.log(res.data);
            }
            else if(stage === 2)
            {
                res.data = await res.data.filter((a)=>{
                    //console.log(a.currstage,status);
                    return a.attendance === "Present" && ( a.currstage === "Approved" || a.currstage === "FGD") ;
                })

            }
            else if(stage === 3)
            {
                res.data = await res.data.filter((a)=>{
                    //console.log(a.currstage,status);
                    return a.attendance === "Present" && (a.currstage === "Approved") ;
                })

            }

            setAppointmentlist(res.data);
            
        }
    }

    const pageOptions = {
        pageSize: 5, pageSizes: true
    };

    let grid;
    const toolbar = ['ExcelExport'];
    const toolbarClick = (args) => {
        //console.log("tool ",args.item);
        if (grid && args.item.prefixIcon === "e-pdfexport") 
        {
            grid.pdfExport();
        }
        else if (grid && args.item.prefixIcon === "e-excelexport") 
        {
            grid.excelExport();
        }
    };

    return (
        <>
            <div className='mt-5 w-full flex justify-center gap-2'>
                
                <div className=' bg-white border border-black border-2 rounded-xl pt-4 w-[800px]'>
                    <div className='flex justify-between'>
                        <div className='ml-6 mt-4'>
                            <label className="text-2xl p-2 m-1 font-bold">Search Appointments:</label>
                        </div>
                        <div className='flex flex-row gap-2 items-center bg-white rounded-xl p-2 mr-2'>
                            <label className="text-lg  font-semibold">Filter By:</label>
                            <button 
                                className={searchby==="TimeRange"?"p-2 bg-blue-900 text-white border border-white rounded-xl":"p-2 border border-black rounded-xl"}
                                onClick={()=>setSearchby("TimeRange")}
                            >Time Range</button>
                            <button 
                                className={searchby==="Day"?"p-2 bg-blue-900 text-white border border-white rounded-xl":"p-2 border border-black rounded-xl"}
                                onClick={()=>setSearchby("Day")}
                            >
                                Day & Time
                            </button>
                        </div>

                    </div>
                    {
                        searchby==="Day"
                        ?
                        <div className='w-full flex flex-row items-end justify-center gap-8 p-8 '>
                            <div>
                                <label className='flex items-center w-[125px] font-semibold'>Date : </label>
                                <input type="date" name="startdate" 
                                    className='text-black border border-black p-1 px-2 rounded-lg h-10 w-56'
                                    value={searchdate} 
                                    onChange={(e)=>{
                                        setSearchdate(e.target.value);
                                    }}
                                />
                            </div>

                            <div>
                                <button className='bg-[#9B5DE5] rounded-xl font-bold text-white  h-10 w-56' onClick={getappointments}>
                                    Search
                                </button>
                            </div>
                        </div>
                        :
                        <div className='w-full flex flex-row items-end justify-center gap-8 p-8'>
                            <div>
                                <label className='flex items-center w-[125px] font-semibold'>Start Datetime : </label>
                                <input type="datetime-local" name="startdate" 
                                    className='text-black border border-black p-1 px-2 rounded-lg h-10 w-56'
                                    value={startdate} 
                                    onChange={(e)=>{
                                        setStartdate(e.target.value);
                                    }}
                                />
                            </div>

                            <div>
                                <label className='flex items-center w-[125px] font-semibold'>End Datetime : </label>
                                <input type="datetime-local" name="enddate" 
                                    className='text-black border border-black p-1 px-2 rounded-lg h-10 w-56'
                                    value={enddate} 
                                    onChange={(e)=>{
                                        setEnddate(e.target.value);
                                    }}
                                />
                            </div>

                            <div>
                                
                                <button className='bg-[#9B5DE5] rounded-xl font-bold text-white  h-10 w-56' onClick={getappointments}>
                                    Search
                                </button>
                            </div>
                        </div>

                    }
                    
                </div>
            </div>

            <div className="w-full flex justify-center p-2">
                <div className=" w-[90%] h-[50%] mt-3">
                    <GridComponent 
                        height="300"
                        dataSource={appointmentlist} 
                        allowPaging={true} 
                        pageSettings={{ pageSize: 6 }} 
                        allowSorting={true} 
                        allowFiltering={true}
                        allowTextWrap={true}
                        loadingIndicator={{ indicatorType: 'Shimmer' }}
                        ref={g => grid = g}
                        toolbar={toolbar} 
                        allowPdfExport={true}
                        allowExcelExport={true}
                        toolbarClick={toolbarClick}
                    >
                            {
                                stage === 0
                                &&
                                <ColumnsDirective>
                                    <ColumnDirective field='paymentinfo' headerText="Payment Type" width='120' textAlign="center"/>
                                    <ColumnDirective field='petcetid' headerText="PETCET ID" width='150' textAlign="center"/>
                                    <ColumnDirective field='uhid' headerText="UHID" width='150' textAlign="center"/>
                                    <ColumnDirective field='mrnno' headerText="MRNNO" width='150' textAlign="center"/>
                                    <ColumnDirective field='pname' headerText="Patient Name" width='200' textAlign="center"/>
                                    <ColumnDirective field='phonenumber' headerText="Phone Number" width='200' textAlign="center"/>
                                    <ColumnDirective field='age' headerText="Age" width='100' textAlign="center"/>
                                    <ColumnDirective field='sex' headerText="Sex" width='100' textAlign="center"/>
                                    <ColumnDirective field='approxweight' headerText="Approx Weight" width='200' textAlign="center"/>
                                    <ColumnDirective field='doctorname' headerText="Doctor" width='200' textAlign="center"/>
                                    <ColumnDirective field='docdepartment' headerText="Doctor Dept" width='200' textAlign="center"/>
                                    <ColumnDirective field='datetime' headerText="Time" width='200' textAlign="center"
                                        template={(props) => <span>{props.datetime.slice(0,10)+", "+props.datetime.slice(11,19)}</span>}
                                    />
                                    <ColumnDirective field='attendance' headerText="Attendance" width='200' textAlign="center"/>
                                    <ColumnDirective field='note' headerText="Note" width='300' textAlign="center"/>
                                </ColumnsDirective>
                            }
                            {
                                stage === 1
                                &&
                                <ColumnsDirective>
                                    <ColumnDirective field='petcetid' headerText="PETCET ID" width='150' textAlign="center"/>
                                    <ColumnDirective field='pname' headerText="Patient Name" width='200' textAlign="center"/>
                                    <ColumnDirective field='height' headerText="Height" width='100' textAlign="center"/>
                                    <ColumnDirective field='weight' headerText="Weight" width='100' textAlign="center"/>
                                    <ColumnDirective field='creatinine' headerText="Creatinine" width='100' textAlign="center"/>
                                    <ColumnDirective field='grbs' headerText="GRBS" width='100' textAlign="center"/>
                                    <ColumnDirective field='lmb' headerText="LMB" width='100' textAlign="center"/>
                                </ColumnsDirective>
                            }
                            {
                                stage === 2
                                &&
                                <ColumnsDirective>
                                    <ColumnDirective field='petcetid' headerText="PETCET ID" width='150' textAlign="center"/>
                                    <ColumnDirective field='pname' headerText="Patient Name" width='200' textAlign="center"/>
                                    <ColumnDirective field='preinjection' headerText="Pre-Injection" width='100' textAlign="center"/>
                                    <ColumnDirective field='postinjection' headerText="Post-Injection" width='100' textAlign="center"/>
                                    <ColumnDirective field='balancefdg' headerText="Balance FDG" width='100' textAlign="center"/>                        
                                    <ColumnDirective field='injectiontime' headerText="Injection Time" width='200' textAlign="center"
                                        template={(props) => <span>{props.injectiontime.slice(0,10)+", "+props.injectiontime.slice(11,19)}</span>}
                                    />
                                </ColumnsDirective>
                            }
                            {
                                stage === 3
                                &&
                                <ColumnsDirective>
                                    <ColumnDirective field='petcetid' headerText="PETCET ID" width='150' textAlign="center"/>
                                    <ColumnDirective field='pname' headerText="Patient Name" width='200' textAlign="center"/>
                                    <ColumnDirective field='scanstart' headerText="Scan Start" width='100' textAlign="center"/>
                                    <ColumnDirective field='scanend' headerText="Scan End" width='100' textAlign="center"/>
                                    <ColumnDirective field='approvedby' headerText="Approved By" width='100' textAlign="center"/>                        
                                    <ColumnDirective field='approverremarks' headerText="Approver Remarks" width='300' textAlign="center"/>
                                </ColumnsDirective>
                            }
                        <Inject services={[Page, Sort, Filter, Group, Toolbar, ExcelExport,PdfExport]}/>
                    </GridComponent>
                </div>
            </div>
        
        </>
    )
}

export default Reports;