import { ColumnDirective, ColumnsDirective, TreeGridComponent } from '@syncfusion/ej2-react-treegrid';
import { useEffect } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
const {useState} = require('react');

const Masters = () => {
    
    const alert = useAlert();

    const [masterslist,setMasterslist] = useState({});

    const [doedit,setDoedit] = useState(false);


    const {authtoken} = useSelector(state => state.user);

    useEffect(()=>{
        getmasters();
    },[])

    const validatemasters = () => {
        if(masterslist.petcet === "" || masterslist.petcet === undefined)
        {
            alert.error("Petcet ID is required");
            return false;
        }

        return true;
    }

    const getmasters = async () => {
        
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_LIST_MASTERS;

        //console.log(url);

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken
                }
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching masters");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            setMasterslist(res.data);
        }
    }

    const handleedit = async (e) => {
        e.preventDefault();
        //console.log("editing");
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_UPDATE_MASTERS;

        //console.log(url);

        if(!validatemasters())
        {
            alert.error("Fill in all the details");
            return 
        }

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(masterslist)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when updating masters");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            getmasters();
            alert.success("Masters updated successfully");
            setDoedit(false);
        }
    }

    const handleeditcancel = async (e) => {
        e.preventDefault();
        setDoedit(false);
    }

    if(doedit)
    {
        return(
            <div className='w-full h-full flex justify-center pt-12'>
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    <span className='flex text-2xl font-bold w-full justify-center'>Edit a User</span>
                    <br></br>
                    <form onSubmit={(e) => handleedit(e)}>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[85px]'>PETCET ID : </label>
                            <input type="text" name="firstname" 
                                className='text-black border p-1 rounded-lg'
                                value={masterslist.petcet} 
                                onChange={(e)=>{
                                    masterslist.petcet = e.target.value;
                                    setMasterslist({...masterslist});
                                }}
                            />
                        </div>
                        
                        <div className='flex justify-around pt-6'>
                            <button type="submit" className='flex justify-center gap-2 bg-green-500 border border-2 border-black rounded-xl p-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                                <span className='font-bold'>
                                    Update
                                </span>
                            </button>
                            <button onClick={handleeditcancel} className='border border-2 border-black rounded-xl p-2 flex justify-center gap-2 bg-red-500'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <span className='font-bold'>
                                    Cancel
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='w-full mt-8 ml-8 text-white flex justify-center'>
                <span className="text-white text-5xl mr-4">Masters List</span>
            </div>
        
            <div className='w-full h-full flex justify-center pt-12'>
                
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    
                    <table className="border-collapse border border-slate-500">
                        <thead>
                            <tr>
                                <th className="border border-slate-600 p-2">PETCET ID</th>
                                <th className="border border-slate-700 p-2 text-center">{masterslist.petcet}</th>
                                <th>
                                    <button 
                                        onClick={()=>{setDoedit(true);}}
                                        className='w-full h-full flex justify-center'
                                    >
                                        <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="w-4 h-4 mx-2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                        </svg>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        {/* <tbody>
                            <tr>
                                <td className="border border-slate-700 p-2 text-center">{masterslist.petcet}</td>
                            </tr>
                        </tbody> */}
                    </table>
                    
                </div>
            </div>
        </>
    )
}

export default Masters;