import { useEffect, useState } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Group, Inject, Page, Sort } from '@syncfusion/ej2-react-grids';
import CreateAppointment from './CreateAppointment';
import updatejwt from '../utils/updatejwt';
import Reports from './Reports';

const {login,ujwt,logout} = require('../features/user/userSlice');

const Appointments = () => {

    const alert = useAlert();
    const dispatch = useDispatch();

    const date = new Date();
    const localtime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    //console.log("local time ",localtime);
    const now =  localtime.slice(0, 16);
    const [startdate, setStartdate ]  = useState(now);
    const [enddate, setEnddate ]  = useState(now);

    const [appointmentlist,setAppointmentlist] = useState([]);
    const [reportmode, setReportmode] = useState(false);

    // Day,TimeRange
    const [searchby,setSearchby] = useState("Day")

    const timings = ["07:00:00","08:00:00","09:00:00","10:00:00","11:00:00","12:00:00","13:00:00","14:00:00","15:00:00","16:00:00","17:00:00","18:00:00","19:00:00"];
    const [searchdate,setSearchdate] = useState(new Date().toISOString().slice(0,10));
    const [searchtime,setSearchtime] = useState("");

    const [docreate,setDocreate] = useState(false);
    const [doedit,setDoedit] = useState(false);
    const [dodelete,setDodelete] = useState(false);

    const [createappointment,setCreateappointment] = useState({});
    const [editappointment,setEditappointment] = useState({});
    const [deleteappointment,setDeleteappointment] = useState({});

    const {authtoken,role} = useSelector(state => state.user);

    useEffect(()=>{
        getappointments(true);
    },[])

    const getappointments = async (alltime=false) => {

        let updated = await updatejwt();
        if(updated)
        {
            try
            {
                let logindetails = localStorage.getItem('kidwailogin');
                logindetails = await JSON.parse(logindetails);
                dispatch(ujwt(logindetails));
            }
            catch(error)
            {
                alert.error("Session Expired, Login Again");
                localStorage.clear();
                dispatch(logout());
                return;
            }
        }
        else
        {
            alert.error("Session Expired, Login Again");
            localStorage.clear();
            dispatch(logout());
            return;
        }
                
        let res;
        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_LIST_APPOINTMENT;
        let body={};

        if(startdate !== "" && enddate !== "")
        {
            if(searchby === "Day")
            {
                body = {
                    "startdt" : searchdate+"T"+searchtime+".000Z",
                    "enddt" : searchdate+"T"+searchtime+".000Z"
                }
            }
            else
            {
                body = {
                    "startdt" : startdate+":00.000Z",
                    "enddt" : enddate+":00.000Z"
                }
            }
        }

        if(alltime===true)
        {
            body = {
                "startdt" : "2020-04-30T13:00:00.000Z",
                "enddt" : localtime
            }
        }

        //console.log(body);
        //console.log(url);

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(body)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching appointments");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            setAppointmentlist(res.data);
        }
    }

    const handledelete = async () => {

        let updated = await updatejwt();
        if(updated)
        {
            try
            {
                let logindetails = localStorage.getItem('kidwailogin');
                logindetails = await JSON.parse(logindetails);
                dispatch(ujwt(logindetails));
            }
            catch(error)
            {
                alert.error("Session Expired, Login Again");
                localStorage.clear();
                dispatch(logout());
                return;
            }
        }
        else
        {
            alert.error("Session Expired, Login Again");
            localStorage.clear();
            dispatch(logout());
            return;
        }

        console.log(deleteappointment);
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_DELETE_APPOINTMENT;

        //console.log(url);

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"Delete",
                body:JSON.stringify({"billnum":deleteappointment.billnum})
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when deleting appointments");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            getappointments();
            alert.success("Patient deleted successfully");
            setDodelete(false);
        }
    }

    const handledeletecancel = async () => {
        setDodelete(false);
        getappointments();
    }

    const showstatus = (status) => {
        //console.log("status ",status);
        if(status === "Patientinfo")
        {
            return <span className="text-blue-900 font-bold">Pending</span>
        }
        else if(status === "Approved")
        {
            return <span className="text-green-500 font-bold">Completed</span>
        }
        else if(status === "Rejected")
        {
            return <span className="text-red-500 font-bold">Rejected</span>
        }
        else
        {
            return <span className="text-red-500 font-bold">Diagnosing</span>
        }
    }

    const markattendance =  async (appointment) => {

        let updated = await updatejwt();
        if(updated)
        {
            try
            {
                let logindetails = localStorage.getItem('kidwailogin');
                logindetails = await JSON.parse(logindetails);
                dispatch(ujwt(logindetails));
            }
            catch(error)
            {
                alert.error("Session Expired, Login Again");
                localStorage.clear();
                dispatch(logout());
                return;
            }
        }
        else
        {
            alert.error("Session Expired, Login Again");
            localStorage.clear();
            dispatch(logout());
            return;
        }

        let updateappointment = {...appointment}
        // console.log(appointment);
        updateappointment.attendance = "Present";

        let res;

        let url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_UPDATE_APPOINTMENT;

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(updateappointment)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when marking attendance");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            alert.success("Marked as present.");
            if(searchtime)
                getappointments(false);
            else
                getappointments(true);
        }
    }

    const pageOptions = {
        pageSize: 5, pageSizes: true
    };

    if(docreate)
    {
        return(<CreateAppointment cancel={()=>{setDocreate(false);getappointments();}} />)
    }

    if(doedit)
    {
        return(<CreateAppointment cancel={()=>{setDoedit(false);getappointments();}} editappointment={editappointment} />)
    }

    if(dodelete)
    {
        return(
            <div className="w-full flex justify-center mt-28">
                <div className='bg-white p-4 border rounded-xl'>
                    <span className="text-xl">Are you sure, you want to delete this appointment?</span>
                    <div className="flex justify-center items-center m-2">
                        <button onClick={handledelete} className="p-2 border border-2 rounded-xl text-white bg-blue-900 m-2">
                            Delete
                        </button>

                        <button onClick={handledeletecancel} className="p-2 border border-2 rounded-xl text-white bg-blue-900 m-2">
                            Cancel
                        </button>
                    </div>

                </div>
            </div>
        )
    }

    

    return (
        <div className='w-full'>
            <div className='flex justify-end pr-28'>
                <button className="mr-28 border border-white rounded-lg p-2 text-white" onClick={()=>setReportmode(!reportmode)}>
                    {
                        reportmode
                        ?
                        "Normal View"
                        :
                        "Report View"
                    }
                </button>
            </div>
            {
                reportmode
                ?
                <Reports stage={0}/>
                :
                <>
                    <div className='mt-5 w-full flex justify-center gap-2'>
                        
                        <div className=' bg-white border border-black border-2 rounded-xl pt-4'>
                            <div className='flex justify-between'>
                                <div className='ml-6 mt-4'>
                                    <label className="text-2xl p-2 m-1 font-bold">Search Appointments:</label>
                                </div>
                                <div className='flex flex-row gap-2 items-center bg-white rounded-xl p-2 mr-2'>
                                    <label className="text-lg  font-semibold">Filter By:</label>
                                    <button 
                                        className={searchby==="TimeRange"?"p-2 bg-blue-900 text-white border border-white rounded-xl":"p-2 border border-black rounded-xl"}
                                        onClick={()=>setSearchby("TimeRange")}
                                    >
                                        Time Range
                                    </button>
                                    <button 
                                        className={searchby==="Day"?"p-2 bg-blue-900 text-white border border-white rounded-xl":"p-2 border border-black rounded-xl"}
                                        onClick={()=>setSearchby("Day")}
                                    >
                                        Day & Time
                                    </button>
                                </div>

                            </div>
                            {
                                searchby==="Day"
                                ?
                                <div className='w-full flex flex-row items-end justify-center gap-8 p-8 '>
                                    <div>
                                        <label className='flex items-center w-[125px] font-semibold'>Date : </label>
                                        <input type="date" name="startdate" 
                                            className='text-black border border-black p-1 px-2 rounded-lg h-10 w-56'
                                            value={searchdate} 
                                            onChange={(e)=>{
                                                setSearchdate(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div className=''>
                                        <label className="flex items-center w-[125px] font-semibold">Time : </label>
                                        <select value={searchtime} 
                                            className='text-black border border-black p-1 rounded-lg h-10 w-56'
                                            onChange={(e)=>{
                                                setSearchtime(e.target.value);
                                            }}
                                        >
                                            <option value="">Select a Time</option>
                                            {
                                                timings.map((doctor)=><option key={doctor} value={doctor}>{doctor}</option>)
                                            }
                                            
                                        </select>
                                    </div>

                                    <div>
                                        <button className='bg-[#9B5DE5] rounded-xl font-bold text-white  h-10 w-56' onClick={getappointments}>
                                            Search
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className='w-full flex flex-row items-end justify-center gap-8 p-8'>
                                    <div>
                                        <label className='flex items-center w-[125px] font-semibold'>Start Datetime : </label>
                                        <input type="datetime-local" name="startdate" 
                                            className='text-black border border-black p-1 px-2 rounded-lg h-10 w-56'
                                            value={startdate} 
                                            onChange={(e)=>{
                                                setStartdate(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div>
                                        <label className='flex items-center w-[125px] font-semibold'>End Datetime : </label>
                                        <input type="datetime-local" name="enddate" 
                                            className='text-black border border-black p-1 px-2 rounded-lg h-10 w-56'
                                            value={enddate} 
                                            onChange={(e)=>{
                                                setEnddate(e.target.value);
                                            }}
                                        />
                                    </div>

                                    <div>
                                        
                                        <button className='bg-[#9B5DE5] rounded-xl font-bold text-white  h-10 w-56' onClick={getappointments}>
                                            Search
                                        </button>
                                    </div>
                                </div>

                            }
                            
                        </div>
                        
                        <button 
                            className='p-2 border border-2 border-white rounded-xl text-white text-xl font-bold'
                            onClick={() => setDocreate(true)}
                        >
                            Add appointment
                        </button>
                    </div>

                    <div className="w-full flex justify-center p-2">
                        <div className=" w-[90%] h-[50%] mt-3">
                            <GridComponent 
                                height="300"
                                dataSource={appointmentlist} 
                                allowPaging={true} 
                                pageSettings={pageOptions} 
                                allowSorting={true} 
                                allowFiltering={true}
                                allowTextWrap={true}
                                loadingIndicator={{ indicatorType: 'Shimmer' }}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective headerText='Attendance' textAlign="center"
                                        template={(props) => (
                                            props.attendance==="Present"
                                            ?
                                            <span className="text-green-500 font-bold">Present</span>
                                            :
                                            <button 
                                                onClick={()=>{markattendance(props);}}
                                                className='w-full h-full flex justify-center p-1 border border-black rounded-xl'
                                            >
                                                Mark as Present
                                            </button>
                                        )} 
                                        width='200'
                                    ></ColumnDirective>
                                    {
                                        role === "Admin"
                                        &&
                                        <ColumnDirective headerText='Edit' textAlign="center"
                                            template={(props) => (
                                                <button 
                                                    onClick={()=>{setEditappointment({...props});setDoedit(true);}}
                                                    className='w-full h-full flex justify-center'
                                                >
                                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="w-4 h-4">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                    </svg>
                                                </button>
                                            )} 
                                            width='80'
                                        ></ColumnDirective>
                                    }
                                    {
                                        role === "Admin"
                                        &&
                                        <ColumnDirective headerText='Delete' textAlign="center"
                                            template={(props) => (
                                                <button 
                                                    onClick={()=>{setDeleteappointment(props);setDodelete(true);}}
                                                    className='w-full h-full flex justify-center'
                                                >
                                                    <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="w-4 h-4">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                
                                                </button>
                                            )} 
                                            width='100'
                                        ></ColumnDirective>
                                    }
                                    <ColumnDirective field='billnum' headerText="Bill No" width='100' textAlign="center"/>
                                    <ColumnDirective field='pname' headerText="Patient Name" width='200' textAlign="center"/>
                                    <ColumnDirective field='phonenumber' headerText="Ph Number" width='200' textAlign="center"/>
                                    <ColumnDirective field='age' headerText="Age" width='80' textAlign="center"/>
                                    <ColumnDirective field='datetime' headerText="Time" width='200' textAlign="center"
                                        template={(props) => <span>{props.datetime.slice(0,10)+", "+props.datetime.slice(11,19)}</span>}
                                    />
                                    <ColumnDirective field='doctorname' headerText="Doctor" width='200' textAlign="center"/>
                                    <ColumnDirective field='paymentinfo' headerText="Payment" width='120' textAlign="center"/>
                                    <ColumnDirective 
                                        headerText="Status" 
                                        width='200' 
                                        textAlign="center"
                                        template={(props)=>{return showstatus(props.currstage)}}
                                    />
                                </ColumnsDirective>
                                <Inject services={[Page, Sort, Filter, Group]}/>
                            </GridComponent>
                        </div>
                    </div>

                </>
            }
        </div>
    )
}

export default Appointments;