import { useAlert } from "react-alert";
import "./App.css";
import Login from "./pages/Login";
import Home from "./pages/Home";
import { useSelector } from "react-redux";

function App() {
  const alert = useAlert();
  const {isloggedin} = useSelector(state => state.user);
  //console.log("logged in? ",isloggedin);
  return (
    <div className="app w-full h-full">
      {
        isloggedin
        ?
        <Home></Home>
        :
        <Login></Login>
      }
    </div>
  );
}

export default App;
