import { ColumnDirective, ColumnsDirective, TreeGridComponent } from '@syncfusion/ej2-react-treegrid';
import { useEffect } from 'react';
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import updatejwt from '../utils/updatejwt';

const {useState} = require('react');

const {login,ujwt,logout} = require('../features/user/userSlice');

const Users = () => {
    
    const alert = useAlert();
    const dispatch = useDispatch();

    const [userlist,setUserlist] = useState([]);

    const [docreate,setDocreate] = useState(false);
    const [doedit,setDoedit] = useState(false);

    const [createuser,setCreateuser] = useState({});
    const [edituser,setEdituser] = useState({});

    const {authtoken} = useSelector(state => state.user);

    useEffect(()=>{
        getusers();
    },[])

    const validateuser = (user) => {
        if((user.firstname && user.lastname && user.email 
            && user.password && user.role 
            && (user.isactive !== "")))
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    const getusers = async () => {

        let updated = await updatejwt();
        if(updated)
        {
            try
            {
                let logindetails = localStorage.getItem('kidwailogin');
                logindetails = await JSON.parse(logindetails);
                dispatch(ujwt(logindetails));
            }
            catch(error)
            {
                alert.error("Session Expired, Login Again");
                localStorage.clear();
                dispatch(logout());
                return;
            }
        }
        else
        {
            alert.error("Session Expired, Login Again");
            localStorage.clear();
            dispatch(logout());
            return;
        }
        
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_LIST_USER;

        //console.log(url);

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken
                }
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching users");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            setUserlist(res.data);
        }
    }

    const handleedit = async (e) => {
        e.preventDefault();
        console.log("editing");
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_UPDATE_USER;

        //console.log(url);

        if(!validateuser(edituser))
        {
            alert.error("Fill in all the details");
            return 
        }

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(edituser)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching users");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            getusers();
            alert.success("User updated successfully");
            setDoedit(false);
        }
    }

    const handlecreate = async (e) => {
        e.preventDefault();
        console.log("editing");
        let res;
        const url = "" + process.env.REACT_APP_BE_API_URL + process.env.REACT_APP_CREATE_USER;

        //console.log(url);

        if(!validateuser(createuser))
        {
            alert.error("Fill in all the details");
            return 
        }

        try
        {
            res = await fetch(url,{
                headers:{
                    "Authorization":"Token " + authtoken,
                    "Content-Type": "application/json"
                },
                method:"POST",
                body:JSON.stringify(createuser)
            })
            
            res = await res.json();
        }
        catch(error)
        {
            alert.error("Some error occured when fetching users");
            console.log(error);
        }

        if(res.status === "Failure")
        {
            alert.error(res.errormessage);
        }
        else if(res.status === "Success")
        {
            setUserlist([res.data[0],...userlist]);
            alert.success("User created successfully");
            setCreateuser({});
            setDocreate(false);
        }
    }

    const handleeditcancel = async (e) => {
        e.preventDefault();
        setDoedit(false);
    }

    const handlecreatecancel = async (e) => {
        e.preventDefault();
        setDocreate(false);
    }
    //console.log(edituser);

    if(docreate)
    {
        return(
            <div className='w-full h-full flex justify-center pt-12'>
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    <span className='flex text-2xl font-bold w-full justify-center'>Create a User</span>
                    <br></br>
                    <form onSubmit={(e) => handlecreate(e)}>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[85px]'>Firstname : </label>
                            <input type="text" name="firstname" 
                                className='text-black border p-1 rounded-lg'
                                value={createuser.firstname} 
                                onChange={(e)=>{
                                    createuser.firstname = e.target.value;
                                    setCreateuser({...createuser});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[85px]'>Lastname : </label>
                            <input type="text" name="lastname" 
                                className='text-black border p-1 rounded-lg'
                                value={createuser.lastname} 
                                onChange={(e)=>{
                                    createuser.lastname = e.target.value;
                                    setCreateuser({...createuser});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[85px]'>Email : </label>
                            <input type="text" name="email" 
                                className='text-black border p-1 rounded-lg'
                                value={createuser.email} 
                                onChange={(e)=>{
                                    createuser.email = e.target.value;
                                    setCreateuser({...createuser});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[85px]">Password : </label>
                            <input type="text" name="password" 
                                className='text-black border p-1 rounded-lg'
                                value={createuser.password} 
                                onChange={(e)=>{
                                    createuser.password = e.target.value;
                                    setCreateuser({...createuser});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[85px]">Role : </label>
                            <select value={createuser.role} 
                                className='text-black border p-1 rounded-lg'
                                onChange={(e)=>{
                                    createuser.role = e.target.value;
                                    setCreateuser({...createuser});
                                }}
                            >
                                <option value="">Select a Role</option>
                                <option value="Admin">Admin</option>
                                <option value="Doctor">NM Tech</option>
                                <option value="FGD">FDG</option>
                                <option value="Nurse">Nurse</option>
                                <option value="Receptionist">Receptionist</option>
                            </select>
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[85px]">isActive : </label>
                            <select value={createuser.isactive} 
                                className='text-black border p-1 rounded-lg'
                                onChange={(e)=>{
                                    createuser.isactive = e.target.value;
                                    setCreateuser({...createuser});
                                }}
                            >
                                <option value="">Select a status</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div className='flex justify-around pt-6'>
                            <button type="submit" className='flex justify-center gap-2 bg-green-500 border border-2 border-black rounded-xl p-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                </svg>
                                <span className='font-bold'>
                                    Create
                                </span>
                            </button>
                            <button onClick={handlecreatecancel} className='border border-2 border-black rounded-xl p-2 flex justify-center gap-2 bg-red-500'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <span className='font-bold'>
                                    Cancel
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    if(doedit)
    {
        return(
            <div className='w-full h-full flex justify-center pt-12'>
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    <span className='flex text-2xl font-bold w-full justify-center'>Edit a User</span>
                    <br></br>
                    <form onSubmit={(e) => handleedit(e)}>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[85px]'>Firstname : </label>
                            <input type="text" name="firstname" 
                                className='text-black border p-1 rounded-lg'
                                value={edituser.firstname} 
                                onChange={(e)=>{
                                    edituser.firstname = e.target.value;
                                    setEdituser({...edituser});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[85px]'>Lastname : </label>
                            <input type="text" name="lastname" 
                                className='text-black border p-1 rounded-lg'
                                value={edituser.lastname} 
                                onChange={(e)=>{
                                    edituser.lastname = e.target.value;
                                    setEdituser({...edituser});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className='flex items-center w-[85px]'>Email : </label>
                            <input type="text" name="password" 
                                className='text-black border p-1 rounded-lg'
                                value={edituser.email}
                                readOnly 
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[85px]">Password : </label>
                            <input type="text" name="password" 
                                className='text-black border p-1 rounded-lg'
                                value={edituser.password} 
                                onChange={(e)=>{
                                    edituser.password = e.target.value;
                                    setEdituser({...edituser});
                                }}
                            />
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[85px]">Role : </label>
                            <select value={edituser.role} 
                                className='text-black border p-1 rounded-lg'
                                onChange={(e)=>{
                                    edituser.role = e.target.value;
                                    setEdituser({...edituser});
                                }}
                            >
                                <option value="">Select a Role</option>
                                <option value="Admin">Admin</option>
                                <option value="Doctor">NM Tech</option>
                                <option value="FGD">FDG</option>
                                <option value="Nurse">Nurse</option>
                                <option value="Receptionist">Receptionist</option>
                            </select>
                        </div>
                        <div className='flex py-1'>
                            <label className="flex items-center w-[85px]">isActive : </label>
                            <select value={edituser.isactive} 
                                className='text-black border p-1 rounded-lg'
                                onChange={(e)=>{
                                    edituser.isactive = e.target.value;
                                    setEdituser({...edituser});
                                }}
                            >
                                <option value="">Select a value</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div className='flex justify-around pt-6'>
                            <button type="submit" className='flex justify-center gap-2 bg-green-500 border border-2 border-black rounded-xl p-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                </svg>
                                <span className='font-bold'>
                                    Update
                                </span>
                            </button>
                            <button onClick={handleeditcancel} className='border border-2 border-black rounded-xl p-2 flex justify-center gap-2 bg-red-500'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                                <span className='font-bold'>
                                    Cancel
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='w-full mt-8 ml-8 text-white flex justify-center'>
                <span className="text-white text-5xl mr-4">Users List</span>
                <button 
                    className='p-2 border border-2 border-white rounded-xl font-bold'
                    onClick={() => setDocreate(true)}
                >
                    Add User
                </button>
            </div>
        
            <div className='w-full h-full flex justify-center pt-12'>
                
                <div className="bg-white h-fit p-8 border border-black border-2 rounded-xl">
                    
                    <table className="border-collapse border border-slate-500">
                        <thead>
                            <tr>
                                <th className="border border-slate-600 p-2">Firstname</th>
                                <th className="border border-slate-600 p-2">Lastname</th>
                                <th className="border border-slate-600 p-2">Email</th>
                                <th className="border border-slate-600 p-2">Password</th>
                                <th className="border border-slate-600 p-2">Role</th>
                                <th className="border border-slate-600 p-2">isActive?</th>
                                <th className="border border-slate-600 p-2">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userlist.map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="border border-slate-700 p-2 text-center">{user.firstname}</td>
                                            <td className="border border-slate-700 p-2 text-center">{user.lastname}</td>
                                            <td className="border border-slate-700 p-2 text-center">{user.email}</td>
                                            <td className="border border-slate-700 p-2 text-center">{user.password}</td>
                                            <td className="border border-slate-700 p-2 text-center">{user.role==="FGD"
                                            ?
                                            "FDG"
                                            :
                                            (user.role==="Doctor"?"NM Tech":user.role)
                                            }</td>
                                            <td className="border border-slate-700 p-2 text-center">{user.isactive ? "Yes" : "No"}</td>
                                            <td className="border border-slate-700 p-2 text-center">
                                                <button onClick={()=>{setEdituser(user);setDoedit(true);}}>
                                                <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" className="w-4 h-4">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                </svg>

                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                    
                </div>
            </div>
        </>
    )
}

export default Users;